<template>
  <div class="wrapper">
    <div class="page">
      <div id="main-loading-cover"  :class="{'disabled_content':loading}">
        <div class="page-inner" id="main_page">
          <div class="va-card mb-4">
            <div class="row">
              <div class="col-lg-12">
                <div class="dash_card_body">
                  <div class="header_section">
                    <h4 class="menu_header">API Configuration Settings</h4>
                  </div>
                </div>
                <hr>
              </div>
              <div class="col-lg-12">
                <div class="dash_card_body">
                  <form @submit.prevent="createAppConfigs">
              
                    <div class="row">
                      <div class="col-md-12">
                        <div v-if="errors ">
                          <div class="alert alert-danger">
                            <ul>
                              <li v-for="(err_msg,errindex) in errorMsg" :key="errindex">{{err_msg}}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-lg-6">
                        <div>
                          <div class="setting_header">
                            <h2>Page Options</h2>
                          </div>
                          <div class="setting_content">
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="page_title">Page Title:</label>
                                <div class="col-sm-7">
                                  <input type="text" v-model="pageTitle" class="form-control" placeholder="Enter  Cart Page Title">
                                </div>
                              </div>
                            </div>
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="page_title"> Site Name:</label>
                                <div class="col-sm-7">
                                  <input type="text" v-model="siteName" class="form-control" placeholder="Enter   Site Name">
                                </div>
                              </div>
                            </div>

                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="page_title"> Embedded Link:</label>
                                <div class="col-sm-7">
                                  <input type="text" v-model="clientUrl" class="form-control" placeholder="Enter Widget Link">
                                </div>
                              </div>
                            </div>


                            <!-- <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="page_title"> Embedded Link:</label>
                                <div class="col-sm-7">
                                  <input type="text" v-model="embeddedLink" class="form-control" placeholder="Enter Embedded link">
                                </div>
                              </div>
                            </div> -->
                  

                          </div>
                        </div>
                   
                        <div>
                          <div class="setting_header">
                            <h2>Ordering Options Setup</h2>
                          </div>
                          <div class="form-group setting_form_grp mt-4">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="mbl_type"> Ordering Setup:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="orderingType" :value="1" checked>&nbsp; Online </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="orderingType" :value="0"> &nbsp; Inhouse</label>
                              </div>
                            </div>
                          </div>
                          <transition name="fade">
                            <div v-if="orderingType == 0">
                              <div class="form-group setting_form_grp mt-4">
                                <div class="row">
                                  <label class="control-label col-sm-5 text-right"  for="mbl_type">Enable Customer Journey?:</label>
                                  <div class="col-sm-7">
                                    <label class="radio-inline"><input type="radio" v-model="customerJourney" :value="1" checked>&nbsp; Yes </label> &nbsp;&nbsp;
                                    <label class="radio-inline"><input type="radio" v-model="customerJourney" :value="0"> &nbsp; No</label>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group setting_form_grp mt-4">
                                <div class="row">
                                  <label class="control-label col-sm-5 text-right"  for="mbl_type">Enable Waiter Journey?:</label>
                                  <div class="col-sm-7">
                                    <label class="radio-inline"><input type="radio" v-model="waiterJourney" :value="1" checked>&nbsp; Yes </label> &nbsp;&nbsp;
                                    <label class="radio-inline"><input type="radio" v-model="waiterJourney" :value="0"> &nbsp; No</label>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group setting_form_grp mt-4">
                                <div class="row">
                                  <label class="control-label col-sm-5 text-right" for="mbl_type">Enable Table Ordering:</label>
                                  <div class="col-sm-7">
                                    <label class="radio-inline"><input type="radio" v-model="tableOrdering" :value="1" checked>&nbsp; Yes </label> &nbsp;&nbsp;
                                    <label class="radio-inline"><input type="radio" v-model="tableOrdering" :value="0" :disabled="disableTable"> &nbsp; No</label>
                                  </div>
                                </div>
                              </div>
                              <transition name="fade">
                                <div v-if="tableOrdering == 1">
                                  <div class="form-group setting_form_grp mt-4">
                                    <div class="row">
                                      <label class="control-label col-sm-5 text-right" for="lv_name_url"> Minimum Table Number :</label>
                                      <div class="col-sm-7">
                                        <input type="text" class="form-control" v-model="minTable" placeholder="Enter  Minimum Table Number">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group setting_form_grp mt-4">
                                    <div class="row">
                                      <label class="control-label col-sm-5 text-right" for="lv_name_url"> Maximum Table Number :</label>
                                      <div class="col-sm-7">
                                        <input type="text" class="form-control" v-model="maxTable" placeholder="Enter  Maximum Table Number ">
                                      </div>
                                    </div>
                                  </div>
                                  <!--  -->
                                </div>
                              </transition>

                              <div class="form-group setting_form_grp mt-4">
                                <div class="row">
                                  <label class="control-label col-sm-5 text-right" for="mbl_type">Enable Room Ordering:</label>
                                  <div class="col-sm-7">
                                    <label class="radio-inline"><input type="radio" v-model="roomOrdering" :value="1" checked>&nbsp; Yes </label> &nbsp;&nbsp;
                                    <label class="radio-inline"><input type="radio" v-model="roomOrdering" :value="0" :disabled="disableRoom"> &nbsp; No</label>
                                  </div>
                                </div>
                              </div>
                              <transition name="fade">
                                <div v-if="roomOrdering ==1">
                                  <div class="form-group setting_form_grp mt-4" >
                                    <div class="row">
                                      <label class="control-label col-sm-5 text-right" for="lv_name_url">Service: <small>(table,pool bed etc)</small></label>
                                      <div class="col-sm-7">
                                        <input type="text" class="form-control" v-model="roomService" >
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group setting_form_grp mt-4" >
                                    <div class="row">
                                      <label class="control-label col-sm-5 text-right" for="lv_name_url"> Minimum Room Number :</label>
                                      <div class="col-sm-7">
                                        <input type="text" class="form-control" v-model="minRoom" placeholder="Enter Minimum Room Number">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group setting_form_grp mt-4" >
                                    <div class="row">
                                      <label class="control-label col-sm-5 text-right" for="lv_name_url">Maximum Room Number:</label>
                                      <div class="col-sm-7">
                                        <input type="text" class="form-control" v-model="maxRoom" placeholder="Enter Maximum Room Number">
                                      </div>
                                    </div>
                                  </div>
                                  <!--  -->
                                </div>
        
                                <!-- div live setup -->
                              </transition>
                            </div>
                          </transition>

                      
                          <!-- end div setup -->
                          <!-- div live setup -->
                      
                        </div>
                        <div>

                          <div class="setting_header">
                            <h2>
                              Ordering Settings
                            </h2>
                          </div>
                          <div class="setting_content">
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label text-right col-sm-5" for="delivery_notification_email">Order Preparation Time (mins)*:</label>
                                <div class="col-sm-7">
                                  <input type="text" class="form-control" v-model="orderPrepTime" placeholder="Enter  Order Preparation Time (mins)">
                                </div>
                              </div>
                            </div>
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label text-right col-sm-5" for="support_phone">Approximate time to deliver*:</label>
                                <div class="col-sm-7">
                                  <input type="text" class="form-control" v-model="orderDeliveryTime" placeholder="Enter Approximate time to deliver">
                                </div>
                              </div>
                            </div>
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label text-right col-sm-5" for="support_email"> Restaurant Order Radius (km)*:</label>
                                <div class="col-sm-7">
                                  <input type="text" class="form-control" v-model="orderDeliveryDistance" placeholder="Enter Restaurant Order Radius (km)">
                                </div>
                              </div>
                            </div>

                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="enable_mbl">Customer Data Required:</label>
                                <div class="col-sm-7">
                                  <label class="radio-inline"><input type="radio" v-model="orderFormValidation"  :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                  <label class="radio-inline"><input type="radio" v-model="orderFormValidation" :value="0"> &nbsp; No</label>
                                </div>
                              </div>
                            </div>

                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="to_name"> Enable Future Order:</label>
                                <div class="col-sm-7">
                                  <label class="radio-inline"><input type="radio" v-model="enableFutureOrder" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                  <label class="radio-inline"><input type="radio" v-model="enableFutureOrder" :value="0"> &nbsp; No</label>

                                </div>
                              </div>
                            </div>

                            <div v-if="enableFutureOrder">
                              <div class="form-group setting_form_grp mt-4">
                                <div class="row">
                                  <label class="control-label col-sm-5 text-right" for="to_name"> Is Future Order Mandatory:</label>
                                  <div class="col-sm-7">
                                    <label class="radio-inline"><input type="radio" v-model="futureOrderMandatory" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                    <label class="radio-inline"><input type="radio" v-model="futureOrderMandatory" :value="0"> &nbsp; No</label>

                                  </div>
                                </div>
                              </div>
                              
                            </div>

                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="to_name"> Minimum time allowed for future Order(hrs):</label>
                                <div class="col-sm-7">
                                  <input type="number" class="form-control" v-model="minFutureTime">
                                </div>
                              </div>
                            </div>

                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="to_name">Use mark as Ready:</label>
                                <div class="col-sm-7">
                                  <label class="radio-inline"><input type="radio" v-model="useMarkAsready" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                  <label class="radio-inline"><input type="radio" v-model="useMarkAsready" :value="0"> &nbsp; No</label>

                                </div>
                              </div>
                            </div>

                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="to_name"> Allow Order Notifications:</label>
                                <div class="col-sm-7">
                                  <label class="radio-inline"><input type="radio" v-model="allowOrderNotification" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                  <label class="radio-inline"><input type="radio" v-model="allowOrderNotification" :value="0"> &nbsp; No</label>

                                </div>
                              </div>
                            </div>
                            
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="to_name"> Offer Based On:</label>
                                <div class="col-sm-7">
                                  <label class="radio-inline"><input type="radio" v-model="disCountType" :value="1">&nbsp; Discounts </label> &nbsp;&nbsp;
                                  <label class="radio-inline"><input type="radio" v-model="disCountType" :value="0"> &nbsp; Items</label>

                                </div>
                              </div>
                            </div>

                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="enable_mbl">Enable scheduled Reports:</label>
                                <div class="col-sm-7">
                                  <label class="radio-inline"><input type="radio" v-model="scheduledReport"  :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                  <label class="radio-inline"><input type="radio" v-model="scheduledReport" :value="0"> &nbsp; No</label>
                                </div>
                              </div>
                            </div>

                            <div class="form-group setting_form_grp mt-4" v-if="scheduledReport">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="enable_mbl">Report Notification Emails:</label>
                                <div class="col-sm-7">
                                  <input type="text" class="form-control" v-model="reportNotificationEmail" placeholder="Enter Delivery Notification Email">
                                </div>
                              </div>
                            </div>

                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="mbl_type">Allow printing:</label>
                                <div class="col-sm-7">
                                  <label class="radio-inline"><input type="radio" v-model="allowPrinting" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                  <label class="radio-inline"><input type="radio" v-model="allowPrinting" :value="0"> &nbsp; No</label>
                                </div>
                              </div>
                            </div>

                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="mbl_type">Allow Price Per Channel:</label>
                                <div class="col-sm-7">
                                  <label class="radio-inline"><input type="radio" v-model="allowPricePerChannel" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                  <label class="radio-inline"><input type="radio" v-model="allowPricePerChannel" :value="0"> &nbsp; No</label>
                                </div>
                              </div>
                            </div> 


                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="enable_post_to_po">Mask Customer Info on Receipt:</label>
                                <div class="col-sm-7">
                                  <label class="radio-inline"><input type="radio" v-model="maskCustomerInfo" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                  <label class="radio-inline"><input type="radio" v-model="maskCustomerInfo" :value="0"> &nbsp; No</label>
                                </div>
                              </div>
                            </div>

                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="enable_post_to_po">External Review Link:</label>
                                <div class="col-sm-7">
                                  <input type="text" v-model="externalReviewLink" class="form-control" placeholder="Enter External Review Link">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <transition name="fade">
                          <div >
                            <div>
                              <div class="setting_header">
                                <h2>Shipping Options</h2>
                              </div>
                              <div>
                                <div class="setting_content">
                                  <div class="form-group setting_form_grp mt-4">
                                    <div class="row">
                                      <label class="control-label col-sm-5 text-right" for="enable_mbl">Add Shipping:</label>
                                      <div class="col-sm-7">
                                        <label class="radio-inline"><input type="radio" v-model="addShipping" name="optradio" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                        <label class="radio-inline"><input type="radio" v-model="addShipping" name="optradio" :value="0"> &nbsp; No</label>

                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group setting_form_grp mt-4">
                                    <div class="row">
                                      <label class="control-label col-sm-5 text-right" for="mbl_type"> Enable Store Pickup:</label>
                                      <div class="col-sm-7">
                                        <label class="radio-inline"><input type="radio" v-model="enableStorepickup" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                        <label class="radio-inline"><input type="radio" v-model="enableStorepickup" :value="0"> &nbsp; No</label>

                                      </div>
                                    </div>
                                  </div>

                                  <div class="form-group setting_form_grp mt-4">
                                    <div class="row">
                                      <label class="control-label col-sm-5 text-right" for="mbl_type"> Enable Inhouse Delivery:</label>
                                      <div class="col-sm-7">
                                        <label class="radio-inline"><input type="radio" v-model="enableInhouseDelivery" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                        <label class="radio-inline"><input type="radio" v-model="enableInhouseDelivery" :value="0"> &nbsp; No</label>

                                      </div>
                                    </div>
                                  </div>


                                  <div class="form-group setting_form_grp mt-4">
                                    <div class="row">
                                      <label class="control-label col-sm-5 text-right" for="mbl_type"> Enable Delivery:</label>
                                      <div class="col-sm-7">
                                        <label class="radio-inline"><input type="radio" v-model="enableDeliveryOption" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                        <label class="radio-inline"><input type="radio" v-model="enableDeliveryOption" :value="0"> &nbsp; No</label>

                                      </div>
                                    </div>
                                  </div>

                                  <div class="form-group setting_form_grp mt-4">
                                    <div class="row">
                                      <label class="control-label col-sm-5 text-right" for="mbl_type"> Enable Kerbside:</label>
                                      <div class="col-sm-7">
                                        <label class="radio-inline"><input type="radio" v-model="enablecarbside" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                        <label class="radio-inline"><input type="radio" v-model="enablecarbside" :value="0"> &nbsp; No</label>

                                      </div>
                                    </div>
                                  </div>

                                  <div class="form-group setting_form_grp mt-4">
                                    <div class="row">
                                      <label class="control-label col-sm-5 text-right" for="mbl_type"> Enable Pre-Order:</label>
                                      <div class="col-sm-7">
                                        <label class="radio-inline"><input type="radio" v-model="enablepreorder" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                        <label class="radio-inline"><input type="radio" v-model="enablepreorder" :value="0"> &nbsp; No</label>

                                      </div>
                                    </div>
                                  </div>

                                  <div class="form-group setting_form_grp mt-4">
                                    <div class="row">
                                      <label class="control-label col-sm-5 text-right" for="mbl_type"> Disable Shipping Cost For Customer:</label>
                                      <div class="col-sm-7">
                                        <label class="radio-inline"><input type="radio" v-model="shippingFreeCustomer" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                        <label class="radio-inline"><input type="radio" v-model="shippingFreeCustomer" :value="0"> &nbsp; No</label>

                                      </div>
                                    </div>
                                  </div>
                               

                                  <div class="form-group setting_form_grp mt-4">
                                    <div class="row">
                                      <label class="control-label col-sm-5 text-right" for="email"> Shipping  Method:</label>
                                      <div class="col-sm-7">
                                        <select class="form-control" v-model="shippingType">
                                          <option value="">Select Shipping Type</option>
                                          <option v-for="option in shippingOptions" :key="option.id" :value="option.id">{{option.shipping_type == 'flat' ? 'internal delivery': option.shipping_type}}</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group setting_form_grp mt-4" v-if="shippingType">
                                    <div class="row">
                                      <label class="control-label col-sm-5 text-right" for="email">Orientation Type:</label>
                                      <div class="col-sm-7">
                                        <label class="radio-inline"><input type="radio" v-model="OrientationType" value="company">&nbsp; Company </label> &nbsp;&nbsp;
                                        <label class="radio-inline"><input type="radio" v-model="OrientationType" value="branch"> &nbsp; Branch</label>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="form-group setting_form_grp mt-4">
                                    <div class="row">
                                      <label class="control-label col-sm-5 text-right" for="email">Using Polygon For Mapping:</label>
                                      <div class="col-sm-7">
                                        <label class="radio-inline"><input type="radio" v-model="usingPolygon" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                        <label class="radio-inline"><input type="radio" v-model="usingPolygon" :value="0"> &nbsp; No</label>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="form-group setting_form_grp mt-4">
                                    <div class="row">
                                      <label class="control-label col-sm-5 text-right" for="mbl_type"> Shipping Api Setup:</label>
                                      <div class="col-sm-7">
                                        <label class="radio-inline"><input type="radio" v-model="shippingApiSetup" :value="1">&nbsp; Live </label> &nbsp;&nbsp;
                                        <label class="radio-inline"><input type="radio" v-model="shippingApiSetup" :value="0"> &nbsp; Demo</label>
                                      </div>
                                    </div>
                                  </div>     

                                </div>
                              </div>
                            </div>
                          </div>
                        </transition>

                        <!-- loyalty -->
                        <div>
                          <div class="setting_header">
                            <h2>
                              Loyalty Settings
                            </h2>
                          </div>
                          <div class="setting_content">
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="dm_consumer_secret">Enable Program:</label>
                                <div class="col-sm-7">
                                  <label class="radio-inline"><input type="radio" v-model="enableLoyalty" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                  <label class="radio-inline"><input type="radio" v-model="enableLoyalty" :value="0"> &nbsp; No</label>
                                </div>
                              </div>
                            </div>
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="dm_consumer_secret">Loyalty Program:</label>
                                <div class="col-sm-7">
                                  <select v-model="loyaltyProgram" class="form-control" id="">
                                    <option value="pesapal">Pesapal</option>
                                    <option value="novacom">Novacom</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="dm_consumer_secret">Loyalty Program Setup:</label>
                                <div class="col-sm-7">
                                  <label class="radio-inline"><input type="radio" v-model="loyaltySetup" :value="1">&nbsp; Live </label> &nbsp;&nbsp;
                                  <label class="radio-inline"><input type="radio" v-model="loyaltySetup" :value="0"> &nbsp; Demo</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                      </div>
                      <!--  -->
                      <div class="col-md-6">
                        <div>
                          <div class="setting_header">
                            <h2>SMS Settings</h2>
                          </div>
                          <div class="setting_content">

                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="dm_consumer_secret">Enable SMS:</label>
                                <div class="col-sm-7">
                                  <label class="radio-inline"><input type="radio" v-model="enableSms" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                  <label class="radio-inline"><input type="radio" v-model="enableSms" :value="0"> &nbsp; No</label>
                                </div>
                              </div>
                            </div>
                            
                            <div class="sms configs" v-if="enableSms">
                              <div class="form-group setting_form_grp mt-4">
                                <div class="row">
                                  <label class="control-label col-sm-5 text-right" for="pos_url">Sms Gateway:</label>
                                  <div class="col-sm-7">
                                    <select name="" class="form-control" v-model="smsGateWay" id="">
                                      <option value="africaIstalking">AfricaIsTalking</option>
                                      <option value="bongasms">BongaSms</option>
                                      <option value="infobip">Infobip</option>
                                      <option value="echomobile">Echomobile</option>
                                      <option value="smsout">Smsout</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div >
                                <div class="form-group setting_form_grp mt-4">
                                  <div class="row">
                                    <label class="control-label col-sm-5 text-right" for="merchant_name">Username:</label>
                                    <div class="col-sm-7">
                                      <input type="text" v-model="smsuserName" class="form-control">
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group setting_form_grp mt-4">
                                  <div class="row">
                                    <label class="control-label col-sm-5 text-right" for="aut_posting"> Password:</label>
                                    <div class="col-sm-7">
                                      <input type="text" v-model="smsPassword" class="form-control">
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                               

                                <div class="form-group setting_form_grp mt-4">
                                  <div class="row">
                                    <label class="control-label col-sm-5 text-right" for="merchant_name">Notice ID:</label>
                                    <div class="col-sm-7">
                                      <input type="text" v-model="noticeId" class="form-control">
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group setting_form_grp">
                                  <div class="row">
                                    <label class="control-label col-sm-5 text-right" for="merchant_name">Token:</label>
                                    <div class="col-sm-7">
                                      <input type="text" v-model="smsToken" class="form-control">
                                    </div>
                                  </div>
                                </div>
                          


                                <div class="form-group setting_form_grp mt-4">
                                  <div class="row">
                                    <label class="control-label col-sm-5 text-right" for="merchant_name">Notification Mask:</label>
                                    <div class="col-sm-7">
                                      <input type="text" v-model="shortCode" class="form-control">
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group setting_form_grp mt-4">
                                  <div class="row">
                                    <label class="control-label col-sm-5 text-right" for="merchant_name">Key:</label>
                                    <div class="col-sm-7">
                                      <input type="text" v-model="smsKey" class="form-control">
                                    </div>
                                  </div>
                                </div>

                                
                                <div class="form-group setting_form_grp mt-4">
                                  <div class="row">
                                    <label class="control-label col-sm-5 text-right" for="aut_posting"> Secret:</label>
                                    <div class="col-sm-7">
                                      <input type="text" v-model="smsSecret" class="form-control">
                                    </div>
                                  </div>
                                </div>

                                <div class="form-group setting_form_grp mt-4">
                                  <div class="row">
                                    <label class="control-label col-sm-5 text-right" for="aut_posting"> Base URL:</label>
                                    <div class="col-sm-7">
                                      <input type="text" v-model="baseUrl" class="form-control">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div>
                          <div class="setting_header">
                            <h2>SMS Options</h2>
                          </div>
                          <div class="setting_content">

                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="pos_url">Order Confirmed:</label>
                                <div class="col-sm-7">
                                  <input type="text" v-model="orderConfirmed" class="form-control">
                                </div>
                              </div>
                            </div>
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="merchant_name"> Order Pickup:</label>
                                <div class="col-sm-7">
                                  <input type="text" v-model="orderPickedup" class="form-control">
                                </div>
                              </div>
                            </div>
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="aut_posting"> Manager New Order:</label>
                                <div class="col-sm-7">
                                  <input type="text" v-model="managerNewOrder" class="form-control">
                                </div>
                              </div>
                            </div>

                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="enable_post_to_po">Add Link to SMS:</label>
                                <div class="col-sm-7">
                                  <label class="radio-inline"><input type="radio" v-model="addSmsLink" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                  <label class="radio-inline"><input type="radio" v-model="addSmsLink" :value="0"> &nbsp; No</label>
                                </div>
                              </div>
                            </div>

                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="merchant_name"> Customer Care Number:</label>
                                <div class="col-sm-7">
                                  <input type="text" v-model="customerCareNumber" class="form-control" placeholder="Enter Customer Care Number">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="setting_header">
                            <h2>
                              Mail Settings
                            </h2>
                          </div>
                          <div class="setting_content">
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="to_name"> Enable Emails:</label>
                                <div class="col-sm-7">
                                  <label class="radio-inline"><input type="radio" v-model="enableEmails" :value="1" checked>&nbsp; Yes </label> &nbsp;&nbsp;
                                  <label class="radio-inline"><input type="radio" v-model="enableEmails" :value="0"> &nbsp; No</label>

                                </div>
                              </div>
                            </div>
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="from_email"> From Email:</label>
                                <div class="col-sm-7">
                                  <input type="text" class="form-control" v-model="fromEmail" placeholder="Enter   From Email">
                                </div>
                              </div>
                            </div>
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="from_name"> From Name:</label>
                                <div class="col-sm-7">
                                  <input type="text" class="form-control" v-model="fromName" placeholder="Enter From Name">
                                </div>
                              </div>
                            </div>
                            <!-- div to have mailer settings -->
                          </div>
                        </div>

                        <div>
                          <div class="setting_header">
                            <h2>
                              Country Settings
                            </h2>
                          </div>
                          <div class="setting_content">
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="to_name">Country:</label>
                                <div class="col-sm-7">
                                  <Multiselect class="form-control"    v-model="countryId" @click="getCountryInfo()" :close-on-select="true" :options="coptions" :custom-label="name" :searchable="true" :placeholder="'Select Country'" label="name" track-by="value"></Multiselect>

                                  <!-- <Select class="form-control" @change="getCountryInfo($event)" >
                                    <option value=""> --Select Country-- </option>
                                    <option v-for="country in countries" :key="country.name" :value="country.code"  >{{ country.name }}</option>
                                  </Select> -->

                                </div>
                              </div>
                            </div>
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="from_email">Country Code:</label>
                                <div class="col-sm-7">
                                  <input type="text" disabled class="form-control" v-model="cCode" placeholder="timeZone">
                                </div>
                              </div>
                            </div>

                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="from_email">Country Phone Code:</label>
                                <div class="col-sm-7">
                                  <input type="text"  class="form-control" v-model="countryPhoneCode" placeholder="country code">
                                </div>
                              </div>
                            </div>
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="from_email">TimeZones:</label>
                                <div class="col-sm-7">
                                  <input type="text" disabled class="form-control" v-model="timeZones" placeholder="timeZone">
                                </div>
                              </div>
                            </div>
                            <!-- div to have mailer settings -->
                          </div>
                        </div>

                        <div>
                          <div class="setting_header">
                            <h2>
                              Add Redirect Url
                            </h2>
                          </div>
                          <div class="setting_content" >
                            <!-- modal for adding role -->
                            <!-- modal -->
                            <div class="modal fade" id="new_menu" role="dialog">
                              <div class="modal-dialog">
                                <div class="modal-content">
                                  <div class="modal-header">Create Sub Menu Item
                                  </div>
                                  <form @submit.prevent="addRoute()">
                                    <div class="modal-body">
                                      <div class="form-group">
                                        <label class="control-label" for="mbl_type">Route:</label>
                                        <div class="mt-3">
                                          <Multiselect v-model="routeName" :close-on-select="true"
                                            :options="routeOptions" :custom-label="name" :searchable="true"
                                            :placeholder="'--Select Route Name--'" label="name" track-by="name">
                                          </Multiselect>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <label class="control-label" for="mbl_type">User Roles:</label>
                                        <div class="mt-3">
                                          <select class="form-control" v-model="userRole" >
                                            <option value="">--Select Role--</option>
                                            <option v-for="role in roles" :key="role.id" :value="role.id">{{ role.name }}</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="modal-footer">
                                      <button type="button" id="create_close_btn" class="btn btn-danger" data-dismiss="modal">Cancel</button>
                                      <button type="submit" class="btn btn-orange"><span v-if="spinner"><i class="fa fa-spinner fa-spin"></i></span>Add Route</button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                            <!-- endmodal -->
                            <!-- end -->

                            <!-- {{ countries }} -->


                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <div class=" col-sm-5 "></div>
                                <div class="col-sm-7">
                                  <button type="button" class="btn btn-default custom_btn custon_orange" data-toggle="modal" data-target="#new_menu">
                                    <i class="fa fa-plus"></i> Add Redirect Url
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="form-group setting_form_grp mt-4" v-if="routeRoles.length > 0">
                              <div  class="row bt-b">
                                <div class="col-md-12">
                                  <div class="pl-5 pr-4 pb-4">
                                   
                                    <div>
                                      <table class="table  table-hover">
                                        <thead>
                                          <th>Role</th>
                                          <th>Redirect Route</th>
                                          <th>Action</th>
                                        </thead>
                                        <tbody>
                                          <tr v-for="routerole in routeRoles" :key="routerole.role">
                                            <td>
                                              {{ userRoles[routerole.role] }}
                                            </td>
                                            <td>
                                              {{ routerole.route }}
                                            </td>
                          
                                          
                                            <td>
                                              <span @click="removeRouteRole(routerole.role )" class="remove_icon glyphicon glyphicon-remove"></span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div> 
                                </div>
                              </div>
                            </div>
                           
                            <!-- div to have mailer settings -->
                          </div>
                        </div>
                    
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <button type="submit" class="btn btn-orange btn-lg"><span v-if="spinner" v-html="searchspinner"></span> <span v-else>Save Changes</span></button>
                      </div>
                    </div>
                    <!-- end row -->
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="main-loader"></div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import navrouters from '@/mixins/routes';
import Multiselect from '@vueform/multiselect';
const toast = useToast();

export default {
    components: {
            Multiselect
    },
    data() {
        return {
            OrientationType:'company',
            usingPolygon:1,
            loading:false,
            errors: false,
            errorMsg: [],
            enableLoyalty:0,
            customerCareNumber:'',
            externalReviewLink:'',
            maskCustomerInfo:0,
            MobileSetup: 1,
            pesapalApiSetup: 1,
            spinner: false,
            searchspinner: "<i class='fa fa-spinner fa-spin '></i> Saving Changes...",
            shippingOptions: [],
            shippingType: 1,
            shippingTypeDetails: '',
            shippingFreeCustomer: 0,
            showCatImages: 1,
            pageTitle: '',
            showProdImages: 1,
            addShipping: 1,
            enableStorepickup: 1,
            enableDeliveryOption: 1,
            customerJourney: 1,
            waiterJourney:1,
            tableOrdering: 1,
            roomOrdering:1,
            fromName: '',
            fromEmail: '',
            enableBasePrice: 0,
            price:0,
            orderDeliveryDistance: '',
            orderDeliveryTime: '',
            orderPrepTime: '',
            countrycode:'',
            cCode:'ke',
            addSmsLink: 1,
            managerNewOrder: '',
            smsGateWay:'africaIstalking',
            smsuserName: '',
            smsPassword:'',
            smsKey:'',
            smsSecret:'',
            orderPickedup: '',
            orderConfirmed: '',
            loyaltySetup:0,
            layout: 1,
            maxRoom:'',
            minRoom:'',
            minTable:'',
            maxTable:'',
            shelvePerRow: 2,
            shelvesHeight: 1,
            siteName: '',
            enableEmails: 1,
            baseUrl:'',
            orderingType: 1,
            smsConfig: null,
            roomService:'',
            disableTable:false,
            shippingApiSetup: 1,
            enablecarbside:0,
            enablepreorder:0,
            disableRoom:false,
            loyaltyProgram:'pesapal',
            embeddedLink:'',
            clientUrl:'',
            enableFutureOrder: 0,
            routeOptions:[],
            enableInhouseDelivery: 0,
            enableSms: 1,
            countryPhoneCode:'',
            userRoles:{
              1:"Super Admin",
              2:"Admin",
              3:"Store Manager",
              4:"User",
              5:"Call Center"
            },
            userRole:'',
            minFutureTime:1,
            routeName:'',
            roles: [],
            countries: [],
            routeRoles:[],
            timeZones:'',
            orderFormValidation: 1,
            disCountType:0,
            allowOrderNotification:0,
            // routes: navrouters.routes,
            layoutSettings: [{
                    'key': 1,
                    'value': 'Modal Theme'
                },
                {
                    'key': 2,
                    'value': 'Collapse Theme'
                },
                {
                    'key': 3,
                    'value': 'Accordion Theme'
                }
            ],
            scheduledReport:1,
            reportNotificationEmail:'',
            noticeId:'',
            coptions: [],
            countryId:'',
            shortCode:'',
            allowPrinting:0,
            allowPricePerChannel:0,
            useMarkAsready:0,
            futureOrderMandatory: 0,
            smsToken:''
        }
    },

    mounted() {
        this.getShippingOptionsSettings();
        if (this.shippingType !== '') {
            this.getShippingDetailById();
        }
        this.getAppConfigs();
        this.getRoles();
        this.getCountries();

        for(let i=0; i < navrouters.routes.length; i++){
          this.routeOptions.push({
                "value": navrouters.routes[i].name,
                "name": navrouters.routes[i].displayname
          })

        }

       
    },
    watch: {
        shippingType() {
            this.getShippingDetailById();
        },
        roomOrdering(){
          if(this.roomOrdering == 0){
            this.disableTable = true;
            this.tableOrdering = 1;
          }else{
            this.disableTable = false
          }
        },
        tableOrdering(){
          if(this.tableOrdering == 0){
            this.disableRoom = true;
          }else{
            this.disableRoom = false;
          }
        }

    },
    methods: {
      getCountryInfo(){
        console.log("country code", this.countryId)
        if(this.countryId){
          const country = this.countries.filter((x) => x.code == this.countryId)
          console.log("country",country[0].code)

          this.timeZones = country[0].timezone
          this.cCode = this.countryId
        }
      },
      async getCountries(){
        try {
          const config = {
              headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
          const url = `${this.dashBaseUrl}/all-countries`;
          const resp = await this.axios.get(url,config)
          if(resp.status == 200){
            this.countries = resp.data.data
            for(let co=0; co<this.countries.length ; co++ ){
              this.coptions.push({
                "value":this.countries[co].code,
                "name": this.countries[co].name
              })
            }
          }
        } catch (error) {
          console.log(error)
        }

      },
      removeRouteRole(role){
        this.routeRoles = this.routeRoles.filter((x) => x.role != role)
      },
      addRoute(){
        const selectedRouteRoles = this.routeRoles.filter((x) => x.role == this.userRole)
        if(selectedRouteRoles.length == 0){
          this.routeRoles.push({
            'role':this.userRole,
            'route':this.routeName
          })
        }
        document.getElementById('create_close_btn').click()
      },
      async getRoles() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = `${this.dashBaseUrl}/roles`;
            this.axios.get(url,config).then((response) => {
                if (response.status === 200) {
                    this.roles =  response.data.data;
                }
            }).catch((err) => {
                console.log('error', err);
            }).finally(()=>  this.loading = false);

        },
        async getShippingOptionsSettings() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.dashBaseUrl + "/shipping-options/" + this.merchantID;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200) {
                    this.shippingOptions = response.data.data
                }
            }).catch((err) => {
                console.log('error', err);
            });
        },
        async getShippingDetailById() {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const url = this.dashBaseUrl + "/shipping-option/" + this.shippingType;
            this.axios.get(url, config).then((response) => {
                if (response.status === 200 && response.data.data !== null) {
                    this.shippingTypeDetails = response.data.data;
                }
            }).catch((err) => {
                console.log('error', err);
            })
        },
        async createAppConfigs() {
            this.spinner = true;
            const url = this.dashBaseUrl + "/create-app-settings";
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            const smsGatewayConfig = {
                'username': this.smsuserName,
                'password': this.smsPassword,
                'notice_id':this.noticeId,
                'notification_mask':this.shortCode,
                'base_url': this.baseUrl,
                'key': this.smsKey,
                'token': this.smsToken,
                'secret': this.smsSecret,
            };
            const payload = {

                'merchant_id':parseInt(this.merchantID),
                'customer_care_number':this.customerCareNumber,
                'external_review_link':this.externalReviewLink,
                'mask_customer_info':this.maskCustomerInfo,
                'site_name':this.siteName,
                'page_title':this.pageTitle,
                'layout_option':this.layout,
                'shelves_number':this.shelvePerRow,
                'same_height_shelves':this.shelvesHeight,
                'show_prod_images': this.showProdImages,
                'show_cat_images':this.showCatImages,
                'add_shipping': this.addShipping,
                'enable_store_pickup':this.enableStorepickup,
                'enable_delivery':this.enableDeliveryOption,
                'enable_inhouse_delivery': this.enableInhouseDelivery,
                'shipping_free_customer': this.shippingFreeCustomer,
                'shipping_type':this.shippingType,
                'enable_mails': this.enableEmails,
                'country_code': this.cCode,
                'order_confirmed_txt': this.orderConfirmed,
                'order_pickup_txt': this.orderPickedup,
                'manager_new_order_txt': this.managerNewOrder,
                'add_sms_link': this.addSmsLink,
                'from_email': this.fromEmail,
                'order_prep_time':this.orderPrepTime,
                'order_delivery_time': this.orderDeliveryTime,
                'order_radius':this.orderDeliveryDistance,
                'from_name':this.fromName,
                'is_base_price': this.enableBasePrice,
                // shipping option payload
                'shipping_api': this.shippingApiSetup,
                'sms_gateway': this.smsGateWay,
                'sms_gateway_config':JSON.stringify(smsGatewayConfig),
                'table_ordering':this.tableOrdering,
                'room_ordering':this.roomOrdering,
                'room_services':this.roomService, 
                'room_max_number':this.maxRoom,
                'room_min_number':this.minRoom,
                'table_min_number':this.minTable,
                'table_max_number':this.maxTable, 
                'customer_journey':this.customerJourney, 
                'waiter_journey':this.waiterJourney,
                'ordering_type':this.orderingType,
                'loyalty_program': this.loyaltyProgram,
                'loyalty_setup': this.loyaltySetup,
                'enable_loyalty': this.enableLoyalty,
                'orientation_type': this.OrientationType,
                'allow_polygon' : this.usingPolygon,
                'client_app_url': this.clientUrl,
                'embedded_link': this.embeddedLink,
                'enable_future_order': this.enableFutureOrder,
                'enable_carbside':this.enablecarbside,
                'enable_preorder':this.enablepreorder,
                'redirect_configs': this.routeRoles,
                'enable_sms':this.enableSms,
                'timezones':this.timeZones,
                'customerdata_required':this.orderFormValidation,
                'offer_type':this.disCountType,
                'enable_order_alerts': this.allowOrderNotification,
                'automate_report':this.scheduledReport,
                'order_notification_emails': this.reportNotificationEmail,
                'allow_printing':this.allowPrinting,
                'future_order_time':this.minFutureTime,
                'allow_multiprice':this.allowPricePerChannel,
                'allow_mark_asready': this.useMarkAsready,
                'future_order_mandatory': this.futureOrderMandatory,
                'country_phone_code':this.countryPhoneCode
            }
            this.axios.post(url, payload, config).then((response) => {
                if (response.status === 200) {
                    toast.success('Change on App config was successful');
                    this.getAppConfigs();
                }
            }).catch((error) => {
                if (error.response.status === 400) {
                    this.errors = true;
                    this.errorMsg = error.response.data.errors
                }else if(error.response.status === 401){
                  toast.error('session has expired');
                  this.$router.push({
                          name: 'login'
                      });
                }
            }).finally(() => this.spinner =false);
        },
        async getAppConfigs(){
          this.loading = true
          const loader = document.getElementById('main-loader');
          loader.style.display = 'block';
          const url = `${this.dashBaseUrl}/configs/${this.merchantID}`;
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
          this.axios.get(url, config).then((response) => {
            if(response.status === 200 && response.data.data.app_settings !==null){
                this.cCode = response.data.data.app_settings.country_code;
                this.countryPhoneCode = response.data.data.app_settings.country_phone_code;
                this.timeZones = response.data.data.app_settings.timezones;
                this.orderFormValidation = response.data.data.app_settings.customerdata_required;
                this.siteName = response.data.data.app_settings.site_name;
                this.pageTitle = response.data.data.app_settings.page_title;
                this.layout = response.data.data.app_settings.layout_option;
                this.shelvePerRow = response.data.data.app_settings.shelves_number;
                this.shelvesHeight = response.data.data.app_settings.same_height_shelves !==undefined ? response.data.data.app_settings.same_height_shelves:1;
                this.showProdImages = response.data.data.app_settings.show_prod_images !==undefined? response.data.data.app_settings.show_prod_images : 1;
                this.showCatImages = response.data.data.app_settings.show_cat_images !==undefined ? response.data.data.app_settings.show_cat_images : 1;
                this.addShipping = response.data.data.add_shipping !==undefined ? response.data.data.app_settings.add_shipping : 1;
                this.enableStorepickup = response.data.data.app_settings.enable_store_pickup !==undefined ? response.data.data.app_settings.enable_store_pickup : 1;
                this.enableDeliveryOption = response.data.data.app_settings.enable_delivery !==undefined ? response.data.data.app_settings.enable_delivery : 1;
                this.shippingFreeCustomer = response.data.data.app_settings.shipping_free_customer !==undefined ? response.data.data.app_settings.shipping_free_customer : 1;
                this.shippingType = response.data.data.app_settings.shipping_type !=undefined ? response.data.data.app_settings.shipping_type : '';
                this.enableBasePrice = response.data.data.app_settings.is_base_price !== undefined ? response.data.data.app_settings.is_base_price : 0;
                this.enableEmails = response.data.data.app_settings.enable_mails !==undefined ? response.data.data.app_settings.enable_mails : 1;
                this.orderConfirmed = response.data.data.app_settings.order_confirmed_txt;
                this.orderPickedup =  response.data.data.app_settings.order_pickup_txt;
                this.managerNewOrder =  response.data.data.app_settings.manager_new_order_txt;
                this.addSmsLink =  response.data.data.app_settings.add_sms_link;
                this.fromEmail =  response.data.data.app_settings.from_email;
                this.orderPrepTime = response.data.data.app_settings.order_prep_time;
                this.orderDeliveryTime = response.data.data.app_settings.order_delivery_time;
                this.orderDeliveryDistance = response.data.data.app_settings.order_radius;
                this.fromName =  response.data.data.app_settings.from_name;
                this.orderingType = response.data.data.app_settings.ordering_type ?  response.data.data.app_settings.ordering_type : 'company'
                this.smsGateWay = response.data.data.app_settings.sms_gateway !==undefined ? response.data.data.app_settings.sms_gateway :'africaIstalking';
                this.tableOrdering = response.data.data.app_settings.enable_table_ordering ? response.data.data.app_settings.enable_table_ordering : 1;
                this.roomOrdering = response.data.data.app_settings.enable_room_ordering ? response.data.data.app_settings.enable_room_ordering : 1;
                this.roomService = response.data.data.app_settings.room_services;
                this.maxRoom = response.data.data.app_settings.room_min_number;
                this.minRoom = response.data.data.app_settings.room_max_number;
                this.minTable = response.data.data.app_settings.table_min_number;
                this.maxTable = response.data.data.app_settings.table_max_number;
                this.enableSms = response.data.data.app_settings.enable_sms
                this.OrientationType = response.data.data.app_settings.orientation_type ?  response.data.data.app_settings.orientation_type : 'company';
                this.enableFutureOrder = response.data.data.app_settings.enable_future_order
                this.usingPolygon = response.data.data.app_settings.allow_polygon ? response.data.data.app_settings.allow_polygon : 0;
                this.enablepreorder = response.data.data.app_settings.enable_preorder;
                this.enablecarbside = response.data.data.app_settings.enable_carbside;
                this.customerJourney = response.data.data.app_settings.enable_customer_journey ? response.data.data.app_settings.enable_customer_journey : 1;
                this.waiterJourney= response.data.data.app_settings.enable_waiter_journey? response.data.data.app_settings.enable_waiter_journey:1;
                this.shippingApiSetup = response.data.data.shipping_options !=undefined ? response.data.data.shipping_options.shipping_api : '';
                this.minFutureTime = response.data.data.app_settings.future_order_time ? response.data.data.app_settings.future_order_time : 0
                this.clientUrl = response.data.data.app_settings.client_app_url
                this.embeddedLink = response.data.data.app_settings.embedded_link
                this.allowPrinting = response.data.data.app_settings.allow_printing !=undefined ? response.data.data.app_settings.allow_printing : 0
                this.loyaltyProgram = response.data.data.loyalty_settings.name ?  response.data.data.loyalty_settings.name : 'pesapal'
                this.loyaltySetup = response.data.data.loyalty_settings.loyalty_setup ? response.data.data.loyalty_settings.loyalty_setup : 0
                this.enableLoyalty = response.data.data.loyalty_settings.enable_loyalty ? response.data.data.loyalty_settings.enable_loyalty : 0
                this.disCountType = response.data.data.app_settings.offer_type ? response.data.data.app_settings.offer_type : 0,
                this.allowOrderNotification = response.data.data.app_settings.enable_order_alerts ? response.data.data.app_settings.enable_order_alerts : 0
                this.scheduledReport = response.data.data.app_settings.automate_report ? response.data.data.app_settings.automate_report : 1
                this.reportNotificationEmail = response.data.data.app_settings.order_notification_emails
                this.allowPricePerChannel = response.data.data.app_settings.allow_multiprice ? response.data.data.app_settings.allow_multiprice : 0
                this.useMarkAsready = response.data.data.app_settings.allow_mark_asready ? response.data.data.app_settings.allow_mark_asready : 0
                this.futureOrderMandatory = response.data.data.app_settings.future_order_mandatory
                this.enableInhouseDelivery =  response.data.data.app_settings.enable_inhouse_delivery ? response.data.data.app_settings.enable_inhouse_delivery : 0
                this.customerCareNumber = response.data.data.app_settings.customer_care_number
                this.externalReviewLink = response.data.data.app_settings.external_review_link
                this.maskCustomerInfo = response.data.data.app_settings.mask_customer_info
               
                if(response.data.data.role_route_redirects){
                  const routesRoles = response.data.data.role_route_redirects;
                  for(let r=0; r < routesRoles.length; r++){
                    const selectedRouteRoles = this.routeRoles.filter((x) => x.role == routesRoles[r].role_id)
                    if(selectedRouteRoles.length == 0){
                      this.routeRoles.push({
                        'role':routesRoles[r].role_id,
                        'route':routesRoles[r].redirect_route
                      })
                    }
                    
                  }
                }

                if(response.data.data.app_settings.sms_gateway_config !== undefined){
                    const smsConfig =  JSON.parse(response.data.data.app_settings.sms_gateway_config);
                    this.smsuserName = smsConfig.username !== undefined? smsConfig.username : '';
                    this.smsPassword =smsConfig.password !== undefined? smsConfig.password:'';
                    this.smsKey =  smsConfig.key !== undefined?smsConfig.key:'';
                    this.smsSecret = smsConfig.secret !== undefined?smsConfig.secret:'';
                    this.baseUrl = smsConfig.base_url !==undefined?smsConfig.base_url: '';
                    this.noticeId = smsConfig.notice_id !=undefined ?  smsConfig.notice_id: ''
                    this.shortCode =  smsConfig.notification_mask != undefined ? smsConfig.notification_mask :''
                }
                
              // 
            }
          }).catch((error) => {
            console.log("error", error)
            if (error.response && error.response.status === 401) {
                            this.$router.push({
                                name: 'login'
                            });
                    }
          }).finally(() => {
            this.loading = false;
            loader.style.display = 'none';
          });
        }
    },
    mixins: [ReusableDataMixins]
}
</script>
