<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">

        <div class="va-card">
          <div class="row">
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header">Stores</h4>
                  <div class="d-flex gap-1">
                    <button class="btn btn-purple" data-toggle="modal" data-target="#confirm_push">
                      <span>
                        <span v-if="updatingVendors">
                          <span v-html="menuspinner"></span>
                        </span>
                        Update Vendor Status
                      </span>
                    </button>

                    <router-link :to="{ name: 'createstore' }">
                      <button class="btn btn-default custom_btn custon_orange">
                        <i class="fa fa-plus"></i> New Store
                      </button>
                    </router-link>
                  </div>

                </div>
                <div class="search_section">
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <div class="form-group has-search mt-1">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="search" @keyup="searchStores()" aria-required="false"
                          autocomplete="on" inputmode="text" name="search_input" placeholder="Search..."
                          class="form-control">
                      </div>
                    </div>

                    <div class="col-md-2 col-sm-12">
                      <div class="form-group has-search">
                        <button class="btn btn-orange search mt-1">Search</button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <select class="form-control mt-1" @change="searchStores()" v-model="storeStatus">
                        <option value="">--Select status --</option>
                        <option value="1">Published</option>
                        <option value="2">Unpublished</option>
                        <option value="4">Trashed</option>
                        <option value="5">All</option>
                      </select>
                    </div>

                  </div>
                </div>


              </div>
              <hr>
            </div>






            <div class="col-lg-12">
              <div class="filters" id="filter_bar" v-if="(user.role.id == 1 || user.role.id == 2) && storeIds.length">

                <span class="btn-filter mt-1">
                  <input type="checkbox" id="uncheck" @click="checkItems('uncheck')" indeterminate=true>
                </span>

                <button class="btn-filter mr-1 mt-1">
                  {{ storeSelected > 1 ? storeSelected + ' stores' : storeSelected + ' store' }} selected
                </button>

                <button class="btn-filter mr-1 mt-1" @click="bulkStatusUpdate('publish', 1)">
                  <i class='fa fa-check' style="color:green;"></i>
                  <span v-if="methodSpinner == 'publish'"><span v-html="spinner"></span> </span> <span v-else>
                    publish</span> </button>
                <button class="btn-filter mr-1 mt-1" @click="bulkStatusUpdate('unpublish', 2)">
                  <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                  <span v-if="methodSpinner == 'unpublish'"><span v-html="spinner"></span></span> <span v-else>
                    Unpublish</span> </button>
                <button class="btn-filter mr-1 mt-1" @click="bulkStatusUpdate('trash', 4)">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                  <span v-if="methodSpinner == 'trash'">
                    <span v-html="spinner"></span>
                  </span>
                  <span v-else> Trash</span>
                </button>
              </div>
              <div class="dash_card_body pt-0">
                <div class="table_section storeList">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th width="5px">
                          <input type="checkbox" id="check_all" @click="checkItems('check')">
                        </th>
                        <th scope="col">Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Vendor Status</th>
                        <th scope="col">Created On</th>
                        <th scope="col">ID</th>
                        <th scope="col" v-if="pesapalConfig.key_per_store">Action</th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="store in stores" :key="store.id">
                        <td>
                          <input type="checkbox" :id="'store_' + store.id" @click="selectStore(store.id)">
                        </td>
                        <td>
                          <router-link :to="{ name: 'updatestore', params: { storeid: store.id } }">
                            {{ store.branch_name }}
                          </router-link>
                        </td>
                        <td>
                          <div class="btn-group">
                            <button class="btn custom_btn btn-sm dropdown-toggle'" type="button">
                              <span v-if="store.status == 1">
                                <i class='fa fa-check' style="color:green;"></i>
                              </span>
                              <span v-else-if="store.status == 2">
                                <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                              </span>
                              <span v-else-if="store.status == 3">
                                <i class="fa fa-archive" aria-hidden="true"></i>
                              </span>
                              <span v-else>
                                <i class="fa fa-trash" aria-hidden="true"></i>
                              </span>
                            </button>
                            <button v-if="user.role.id == 1 || user.role.id == 2" type="button"
                              class="btn btn-sm custom_btn btn-default dropdown-toggle" data-toggle="dropdown"
                              :id="'dropdownMenuButton' + store.id">
                              <span class="caret"></span>
                              <span class="sr-only">Toggle Dropdown</span>
                            </button>
                            <div class="dropdown-menu" :aria-labelledby="'dropdownMenuButton' + store.id">
                              <a class="dropdown-item" @click="updateStatusPerItem(store.id, 1)" href="#">Publish</a>
                              <a class="dropdown-item" @click="updateStatusPerItem(store.id, 2)" href="#">Unpublish</a>
                              <a class="dropdown-item" @click="updateStatusPerItem(store.id, 4)" href="#">Trash</a>
                            </div>
                          </div>
                        </td>
                        <td>
                          {{ store.pause_orders_until && new Date(store.pause_orders_until) > new Date()
                            ? ("Paused until " + formatDate(store.pause_orders_until))
                            : "Online" }}
                        </td>
                        <td>
                          {{ formatDate(store.created_at) }}
                        </td>
                        <td>
                          {{ store.id }}
                        </td>
                        <td v-if="pesapalConfig.key_per_store">
                          <button class="btn btn-default custom_btn custon_orange" @click="updatePaymentIpn(store.id)">
                            <span v-if="ipnloading == store.id"><i class="fa fa-spinner fa-spin"></i></span>Update
                            Ipn</button>
                        </td>
                      </tr>

                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="8">
                          <div id="pagination" v-if="totalPages > 1">
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                </select>
                              </li>

                            </ul>
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page"
                              @pagechanged="onPageChange" />

                          </div>

                        </td>
                      </tr>
                    </tfoot>
                  </table>

                </div>
                <div v-if="loading" class="loading-cover">
                  <div class="loader"> </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="confirm_push" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update store status on third party vendors</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="branch_area">
              <div class="form-group">
                <label>Store Pause Duration: <small><i>(accepts orders if no duration set)</i></small></label>
                <input class="form-control" type="datetime-local" v-model="selectedPauseDuration" />
              </div>
            </div>
            <div class="channel_select">
              <div class="form-group">
                <label>Pause Reason</label>
                <textarea class="form-control" type="text" v-model="selectedReason" rows="3"></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" id="close_menupush" class="btn btn-danger" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-success" @click="updateVendorStatus()">
              <span v-if="updatingVendors">
                <span v-html="menuspinner"></span>
              </span>
              Update Vendors
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins'
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import Pagination from '@/pages/pagination/pagination.vue';
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      stores: [],
      loading: false,
      page: 1,
      totalPages: 0,
      totalRecords: 0,
      recordsPerPage: this.$store.state.storesRecordsPerPage,
      enterpageno: '',
      storeIds: [],
      storeStatus: '',
      methodSpinner: null,
      debounce: null,
      spinner: "<i class='fa fa-spinner fa-spin '></i>",
      search: '',
      storeSelected: 0,
      ipnLoading: false,
      updatingVendors: false,
      selectedPauseDuration: null,
      selectedReason: "",
      selectAllStores: false,
      // storeIds: []
    }
  },
  created: async function () {
    await Promise.all([
      this.getBranches(),
    ])
  },
  methods: {
    async updatePaymentIpn(storeId) {
      try {
        this.ipnloading = storeId
        const config = {
          headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
        };
        const url = `${this.dashBaseUrl}/update-store-ipn/${storeId}`;
        const resp = await this.axios.post(url, {}, config)
        if (resp.status == 200) {
          this.ipnloading = false
          toast.success("ipn updated successfully")
        }
      } catch (error) {
        this.ipnloading = false
        if (error.response.status === 400) {
          let errormsgs = '';
          for (let x = 0; x < error.response.data.errors.length; x++) {
            errormsgs = error.response.data.errors[x] + ','
          }
          toast.error(errormsgs)
        } else if (error.response.status === 401) {
          this.$router.push({
            name: 'login'
          });
        }
      }
    },
    checkItems(type) {
      const uncheck = document.querySelector('#uncheck')
      if (uncheck) { uncheck.indeterminate = true; }
      if (type === 'check') {
        if (document.getElementById('check_all').checked) {
          this.storeSelected = 0;
          for (const store of Object.values(this.stores)) {
            this.storeSelected += 1;
            document.getElementById('store_' + store.id).checked = true
            if (this.storeIds.includes(store.id) === false) {
              this.storeIds.push(store.id);
            }
          }
        } else {
          for (const store of Object.values(this.stores)) {
            document.getElementById('store_' + store.id).checked = false
          }
          this.storeIds = []
        }

      } else {
        document.getElementById('check_all').checked = false
        for (const store of Object.values(this.stores)) {
          document.getElementById('store_' + store.id).checked = false
        }
        this.storeIds = []
      }

    },
    searchStores() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getBranches();
      }, 1000)
    },
    selectStore(storeId) {
      if (document.getElementById('store_' + storeId).checked) {
        if (this.storeIds.includes(storeId) === false) {
          this.storeIds.push(storeId);
        }
      } else {
        if (this.storeIds.indexOf(storeId) !== -1) {
          this.storeIds = this.storeIds.filter((x) => x !== storeId);
        }
        // checking if there is any checked box
        for (const store of Object.values(this.stores)) {
          if (store.id != storeId && document.getElementById('store_' + store.id).checked) {
            break;
          }
        }
        //
      }
      // increasing count on item selected
      this.storeSelected = 0;
      for (const store of Object.values(this.stores)) {
        if (document.getElementById('store_' + store.id).checked) {
          this.storeSelected += 1;
        }
      }
    },
    bulkStatusUpdate(method, status) {
      this.updateStatus(this.storeIds, method, status);
    },
    updateStatusPerItem(storeId, status) {
      const storeIdsArr = [];
      if (storeIdsArr.includes(storeId) === false) {
        storeIdsArr.push(storeId)
      }
      this.updateStatus(storeIdsArr, '', status)
    },
    updateStatus(storeIdArr, method, status) {
      if (storeIdArr.length > 0) {
        this.methodSpinner = method;
        if (method === 'publish' && status === false) {
          this.methodSpinner = 'unpublish'
        }
        const payload = {
          "merchant_id": parseInt(this.merchantID),
          "ids": storeIdArr,
          "status": status
        };
        const config = {
          headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
        };
        const url = this.baseUrl + "/branch/status";
        this.axios.patch(url, payload, config).then((response) => {
          this.storeIds = [];
          if (response.status === 200) {
            this.methodSpinner = null;
            this.getBranches();
          }
        }).catch((error) => {
          this.methodSpinner = null;
          this.storeIds = [];
          if (error.response.status === 401) {
            this.$router.push({
              name: 'login'
            });
          }
        });
      }
    },
    async getBranches() {
      // const url = `${this.baseUrl}//branch?page=${this.page}&size=${this.recordsPerPage}&merchant_id=${this.merchantID}&query=${this.search}`;

      let url = `${this.baseUrl}/branch`;
      url = new URL(url);
      url.searchParams.set('page', this.page);
      url.searchParams.set('size', this.recordsPerPage);
      if (this.storeStatus !== '') {
        url.searchParams.set('status', this.storeStatus);
      }
      if (this.search !== '') {
        url.searchParams.set('query', this.search);
      }
      url.searchParams.set('merchant_id', this.merchantID);

      this.loading = true;
      const config = {
        headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
      };
      this.axios.get(url, config).then((response) => {
        if (response.status === 200) {
          this.loading = false;
          this.stores = response.data.data;
          this.totalPages = Math.ceil(response.data.total / this.recordsPerPage)
          this.totalRecords = response.data.total
        }
      }).catch((error) => {
        if (error.response.status === 401) {
          this.$router.push({
            name: 'login'
          });
        }
      })

    },
    onPageChange(page) {
      this.storeIds = []
      document.getElementById('check_all').checked = false;
      this.page = page
      this.getBranches();
    },
    onChangeRecordsPerPage() {
      const payload = {
        "type": "stores",
        "size": this.recordsPerPage
      }
      this.$store.commit("setPageLimit", payload)
      // setPageLimit
      this.storeIds = []
      document.getElementById('check_all').checked = false;
      this.getBranches();
      // ds
    },
    updateStoreStatus(status, branchId) {
      const storeIds = [];
      storeIds.push(branchId)
      //
      const payload = {
        "merchant_id": parseInt(this.merchantID),
        "ids": storeIds,
        "status": status
      };
      const config = {
        headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
      };
      const url = this.baseUrl + '/branch/publish'
      this.axios.patch(url, payload, config).then((response) => {
        if (response.status === 200) {
          this.getBranches();
        }
      })
        .catch((error) => {
          this.spinner = false;
          if (error.response.status === 401) {
            this.$router.push({
              name: 'login'
            });
          }
        })
      //
    },

    updateVendorStatus() {

      this.updatingVendors = true;
      const config = {
        headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
      };
      const url = this.baseUrl + "/branch/vendor_status";
      let pauseOrdersUntil = this.selectedPauseDuration
      if (pauseOrdersUntil) {
        pauseOrdersUntil = new Date(pauseOrdersUntil).toISOString();
        if (!this.selectedReason) {
          toast.error("A reason is required when pausing orders");
          return
        }
      } else {
        pauseOrdersUntil = null;
      }

      const payload = {
        branches: this.storeIds,
        // eslint-disable-next-line
        pause_orders_until: pauseOrdersUntil,
        reason: this.selectedReason,
        // eslint-disable-next-line
        merchant_id: parseInt(this.merchantID),
      }

      console.log(payload)

      this.axios.patch(url, payload, config)
        .then((response) => {
          if (response.status === 200) {
            this.getBranches();
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$router.push({
              name: 'login'
            });
          }
        })
        .finally(() => {
          document.getElementById('close_menupush').click();
          this.updatingVendors = false;
        })
    },
  },
  mixins: [ReusableFunctionMixins, ReusableDataMixins]
}
</script>

<style lang="css">
.gap-1 {
  gap: 0.5rem;
}
</style>
